import { storeToRefs } from 'pinia';
import type {
  Company,
  CompanyItems,
  CompanyManager
} from '@salvatore0193/definitions/lib/Companies';
import CompaniesService from '~/services/companies';
import CompaniesAdminService from '~/services/admin/companies';
import { InitialState, useCompaniesState } from '~/stores/companies/state';
import TalentServices from '~/services/talent';
import { sessionTTL } from '~/constants';

export const useCompaniesActions = defineStore('companies.actions', () => {
  const {
    isSearched,
    cachedResult,
    company,
    companies,
    keyValuesIndustry,
    companyItems,
    counts,
    types,
    pagination,
    companyUsers,
    user,
    companyMatch
  } = storeToRefs(useCompaniesState());

  const { settings } = useSettings();

  const changePage = (page: number): void => {
    pagination.value.currentPage = page;
  };

  const updateCacheResult = (timestamp: number): void => {
    cachedResult.value = timestamp;
  };

  /**
   * @deprecated
   * @name getCompanies
   * @description Get companies
   */
  async function getCompanies() {
    /**
     * This is a check to verify if a user is logged.
     */
    if (!settings.loggedIn) {
      return undefined;
    }

    /**
     * This is a check to verify if the cached result is still valid Or
     * If we have a "searchValue" cached, we need to use the cache.
     */
    const isCached =
      cachedResult.value && cachedResult.value + sessionTTL > Date.now();

    if (isCached && companies.value.length > 0) {
      return companies.value;
    }

    const response = await CompaniesAdminService.getCompanies();

    if (response) {
      // companies.value = response;
      isSearched.value = false;
      updateCacheResult(Date.now());
      return response;
    }
  }

  async function getCompaniesWithCounts(searchValue?: string) {
    /**
     * This is a check to verify if a user is logged.
     */
    if (!settings.loggedIn) {
      return undefined;
    }

    /**
     * This is a check to verify if the cached result is still valid Or
     * If we have a "searchValue" cached, we need to use the cache.
     */
    const isCached =
      cachedResult.value && cachedResult.value + sessionTTL > Date.now();

    if (isCached && companies.value.length > 0) {
      return companies.value;
    }

    const response = await CompaniesAdminService.getCompaniesWithCounts(
      searchValue
    );

    if (response) {
      companies.value = response.list;
      pagination.value.total = Math.floor(
        response.list.length / pagination.value.perPage
      );
      counts.value = response.counts;
      isSearched.value = false;
      updateCacheResult(Date.now());
      return response.list;
    }
  }

  /**
   * @name getCompaniesByIds
   * @description Don't ask me why, but it's a POST...Get companies by ids
   */
  async function getCompaniesByIdsArray(ids: Array<string>) {
    const response = await CompaniesService.getCompaniesByIdArray(ids);

    if (response) {
      companyMatch.value = response;
      return response;
    }
  }

  async function getCompaniesByIds(id: string) {
    const response = await CompaniesAdminService.getCompaniesById(id);

    if (response) {
      company.value = response.company;
      companyUsers.value = response.company.managers;
      types.value = response.types;
      return response;
    }
  }

  async function getKeyValuesIndustry() {
    if (!settings.loggedIn) {
      return undefined;
    }

    if (keyValuesIndustry && keyValuesIndustry.value) {
      return keyValuesIndustry.value;
    }

    const response = await TalentServices.getKeyValuesIndustry();

    if (response) {
      keyValuesIndustry.value = response;
      return keyValuesIndustry.value;
    }
  }

  const getCompanyItems = async (): Promise<CompanyItems | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    if (companyItems && companyItems.value) {
      return companyItems.value;
    }

    const response = await TalentServices.getCompanyItems();

    if (response) {
      companyItems.value = response;
      return companyItems.value;
    }
  };

  const addNewManager = async (
    id: string,
    manager: Partial<CompanyManager>
  ): Promise<CompanyManager | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CompaniesAdminService.addNewManager(id, manager);

    if (response) {
      user.value = response;
      return response;
    }
  };

  const updateManager = async (
    id: string,
    manager: Partial<CompanyManager>
  ): Promise<CompanyManager | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CompaniesAdminService.updateManager(id, manager);

    if (response) {
      user.value = response;
      return response;
    }
  };

  const deleteManager = async (
    id: string,
    managerId: string
  ): Promise<string | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CompaniesAdminService.deleteManager(id, managerId);

    if (response) {
      user.value = InitialState.user;
      return response;
    }
  };

  const updateCompany = async (
    id: string,
    companyItem: Partial<Company>
  ): Promise<Company | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CompaniesAdminService.updateCompany(id, companyItem);

    if (response) {
      company.value = response;
      return response;
    }
  };

  const updateTalentFlagByCompanyId = async (
    id: string,
    isTalent: boolean
  ): Promise<Company | undefined> => {
    if (!settings.loggedIn) {
      return undefined;
    }

    const response = await CompaniesAdminService.updateTalentFlagByCompanyId(
      id,
      isTalent
    );

    if (response) {
      company.value = response;
      return response;
    }
  };

  return {
    getCompaniesByIds,
    getKeyValuesIndustry,
    getCompanyItems,
    getCompanies,
    getCompaniesWithCounts,
    getCompaniesByIdsArray,
    changePage,
    addNewManager,
    updateManager,
    deleteManager,
    updateCompany,
    updateTalentFlagByCompanyId
  };
});
