import type { ResponseBody as ResponseBodyCompany } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Companies/Id/GET';
import type { ResponseBody as ResponseBodyCompanies } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Companies/GET';
import type { ResponseBody as ResponseBodyCompaniesWithCounts } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Companies/WithCounts/GET';
import type { ResponseBody as ResponseBodyCompaniesManager } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Companies/Managers/POST';
import type { ResponseBody as ResponseBodyCompaniesUpdateManager } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Companies/Managers/PUT';
import type { ResponseBody as ResponseBodyCompaniesDeleteManager } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Companies/Managers/DELETE';
import type { ResponseBody as UpdateCompanyResponse } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Companies/PUT';
import type { ResponseBody as ResponseBodyIsTalent } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Companies/Id/Item/PUT';

import type {
  CompanyManager,
  Company
} from '@salvatore0193/definitions/lib/Companies';
import { execute } from '~/services/utils';
import { GET_COMPANIES, GET_COMPANIES_WITH_COUNTS } from '~/constants';

const getCompanies = async (config?: {
  perPage?: number;
  page?: number;
}): Promise<ResponseBodyCompanies['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCompanies['data']>(
    apiClient.get(
      GET_COMPANIES +
        `${config?.perPage ? `?per_page=${config?.perPage}` : ''}${
          config?.page ? `&page=${config?.page}` : ''
        }`
    )
  );
};

const getCompaniesWithCounts = async (
  search?: string
): Promise<ResponseBodyCompaniesWithCounts['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCompaniesWithCounts['data']>(
    apiClient.get(
      GET_COMPANIES_WITH_COUNTS + `${search ? `?search=${search}` : ''}`
    )
  );
};

const getCompaniesById = async (companyId: string) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCompany['data']>(
    apiClient.get(GET_COMPANIES + `/${companyId}`)
  );
};

const addNewManager = async (
  companyId: string,
  manager: Partial<CompanyManager>
) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCompaniesManager['data']>(
    apiClient.post(GET_COMPANIES + `/${companyId}/manager`, { manager })
  );
};

const updateManager = async (
  companyId: string,
  manager: Partial<CompanyManager>
) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCompaniesUpdateManager['data']>(
    apiClient.put(GET_COMPANIES + `/${companyId}/manager`, { manager })
  );
};

const deleteManager = async (companyId: string, managerId: string) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCompaniesDeleteManager['data']>(
    apiClient.delete(GET_COMPANIES + `/${companyId}/manager/${managerId}`)
  );
};

const updateCompany = async (uid: string, company: Partial<Company>) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<UpdateCompanyResponse['data']>(
    apiClient.put(GET_COMPANIES + `/${uid}`, company)
  );
};

const updateTalentFlagByCompanyId = async (id: string, isTalent: boolean) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyIsTalent['data']>(
    apiClient.put(GET_COMPANIES + `/${id}/item`, {
      fieldName: 'talent',
      fieldValue: isTalent
    })
  );
};

export default {
  getCompanies,
  getCompaniesById,
  getCompaniesWithCounts,
  addNewManager,
  updateManager,
  deleteManager,
  updateCompany,
  updateTalentFlagByCompanyId
};
