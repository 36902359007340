import type { ResponseBody as SkillsCategoriesResponse } from '@salvatore0193/definitions/lib/ServiceLayer/Talent/SkillsCategories/GET';
import type { ResponseBody as KeyValuesIndustryResponse } from '@salvatore0193/definitions/lib/ServiceLayer/Talent/KeyValuesIndustry/GET';
import type { ResponseBody as CandidateItemsResponse } from '@salvatore0193/definitions/lib/ServiceLayer/Talent/CandidateItems/GET';
import type { ResponseBody as CompanyItemsResponse } from '@salvatore0193/definitions/lib/ServiceLayer/Talent/CompanyItems/GET';
import { execute } from '~/services/utils';
import {
  GET_CANDIDATE_ITEMS_REST,
  GET_COMPANY_ITEMS_REST,
  GET_KEYVALUE_INDUSTRY_REST,
  GET_SKILLS_CATEGORIES_REST
} from '~/constants';

const getSkillCategories = async () => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<SkillsCategoriesResponse['data']>(
    apiClient.get(GET_SKILLS_CATEGORIES_REST)
  );
};

const getKeyValuesIndustry = async () => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<KeyValuesIndustryResponse['data']>(
    apiClient.get(GET_KEYVALUE_INDUSTRY_REST)
  );
};

const getCandidateItems = async () => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<CandidateItemsResponse['data']>(
    apiClient.get(GET_CANDIDATE_ITEMS_REST)
  );
};

const getCompanyItems = async () => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<CompanyItemsResponse['data']>(
    apiClient.get(GET_COMPANY_ITEMS_REST)
  );
};

export default {
  getSkillCategories,
  getKeyValuesIndustry,
  getCandidateItems,
  getCompanyItems
};
