import type { ResponseBody } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Matches/Candidate/GET';
import type { ResponseBody as ResponseBodyCompany } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Matches/Company/GET';
import type { ResponseBody as ResponseBodyMatchesByJob } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Matches/Job/GET';
import type { ResponseBody as ResponseBodyMatches } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Matches/GET';
import {
  GET_MATCHES_BY_CANDIDATE_REST,
  GET_MATCHES_BY_COMPANY_REST,
  MATCHES_ADMIN,
  MATCHES_BY_JOB_REST
} from '~/constants';
import { execute } from '~/services/utils';

const getMatchesByCandidateId = async (
  candidateId: string,
  perPage: number
) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBody['data']>(
    apiClient.get(
      GET_MATCHES_BY_CANDIDATE_REST +
        `/${candidateId}` +
        (perPage && `?per_page=${perPage}`)
    )
  );
};

const getMatchesByCompanyId = async (companyId: string) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyCompany['data']>(
    apiClient.get(GET_MATCHES_BY_COMPANY_REST + `/${companyId}`)
  );
};

async function getMatchesByJobId(jobIs: string) {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyMatchesByJob['data']>(
    apiClient.get(MATCHES_BY_JOB_REST + `/${jobIs}?per_page=1000`)
  );
}

async function getMatches(
  filterBy: 'all' | 'matches' | 'applications' | 'openselections' | 'history',
  perPage: number,
  page: number,
  search?: string
) {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyMatches['data']>(
    apiClient.get(
      MATCHES_ADMIN +
        (filterBy && `?filter=${filterBy}`) +
        (perPage && `&per_page=${perPage}`) +
        (page && `&page=${page}`) +
        (search ? `&search=${search}` : '')
    )
  );
}

export default {
  getMatchesByCandidateId,
  getMatchesByCompanyId,
  getMatchesByJobId,
  getMatches
};
