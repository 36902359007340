import type { SelectItem } from '@emotioncod/cm-design-system';
import type { StatCardData } from '@emotioncod/cm-design-system/dist/types/types';
import type {
  JobPosition,
  JobsStats,
  JobPositionsLanguages,
  JobSource
} from '@salvatore0193/definitions/lib/Jobs';
import { Languages, Source } from '@salvatore0193/definitions/lib/Jobs/const';
import { resolveLocationsObject } from '~/resolvers/locations';

export function resolveJobPositionsItemArray(
  jobPositions: Array<JobPosition>
): Array<SelectItem> {
  return jobPositions.map((el) => ({
    value: el.id,
    text: el.name,
    selected: false,
    disabled: false // TAL-336
  }));
}

export const resolveJobPositionByID = (id: string): JobPosition | undefined => {
  const jobPositionsStore = useJobsPositions();

  return jobPositionsStore.jobPositions.find((el) => el.id === id);
};

export function resolveJobPositionsStats(
  counts: {
    jobs: number;
    online: number;
    offline: number;
    candidatesSent: number;
    openSelections: number;
  },
  t: (key: string, ...args: any[]) => string
): StatCardData[] {
  if (!counts) return [];
  return [
    {
      statName: t('jobPositions.stats.totalJobs'),
      iconName: 'mdiBriefcase',
      statValue: counts.jobs,
      iconColor: 'purple'
    },
    {
      statName: t('jobPositions.stats.onlineJobs'),
      iconName: 'mdiBriefcaseCheck',
      statValue: counts.online,
      iconColor: 'blue'
    },
    {
      statName: t('jobPositions.stats.offlineJobs'),
      iconName: 'mdiBriefcaseRemove',
      statValue: counts.offline,
      iconColor: 'red'
    },
    {
      statName: t('jobPositions.stats.candidatesSent'),
      iconName: 'mdiBriefcaseAccount',
      statValue: counts.candidatesSent,
      iconColor: 'green'
    },
    {
      statName: t('jobPositions.stats.openSelections'),
      iconName: 'mdiTargetAccount',
      statValue: counts.openSelections,
      iconColor: 'light-blue'
    }
  ];
}

export function resolveJobPositionStats(
  jobPositions: JobPosition,
  t: (key: string, ...args: any[]) => string
): StatCardData[] {
  if (!jobPositions) return [];

  return [
    {
      statName: t('jobPositions.job.details.stats.workingModel'),
      iconName: 'mdiHomeAutomation',
      statValue: jobPositions.remote,
      iconColor: 'purple'
    },
    {
      statName: t('jobPositions.job.details.stats.workplaces'),
      iconName: 'mdiMapMarker',
      statValue: resolveLocationsObject(jobPositions.location),
      iconColor: 'blue'
    },
    {
      statName: t('jobPositions.job.details.stats.offeredSalary'),
      iconName: 'mdiCurrencyEur',
      statValue: jobPositions.salary_max,
      iconColor: 'red'
    },
    {
      statName: t('jobPositions.job.details.stats.typeOfContract'),
      iconName: 'mdiFileDocumentEdit',
      statValue: jobPositions.type || 'N/A',
      iconColor: 'green'
    },
    {
      statName: t('jobPositions.job.details.stats.requiredExperience'),
      iconName: 'mdiSignalCellular3',
      statValue: jobPositions.experience,
      iconColor: 'light-blue'
    }
  ];
}

export function resolveJobPositionStatistics(
  jobStats: JobsStats | undefined,
  t: (key: string, ...args: any[]) => string
): StatCardData[] {
  if (!jobStats) return [];

  return [
    {
      statName: t('jobPositions.job.statistics.totalOpportunties'),
      iconName: 'mdiAccountMultipleCheck',
      statValue: jobStats.opportunity,
      iconColor: 'blue'
    },
    {
      statName: t('jobPositions.job.statistics.openSelections'),
      iconName: 'mdiTargetAccount',
      statValue: jobStats.openSelections,
      iconColor: 'red'
    },
    {
      statName: t('jobPositions.job.statistics.offer'),
      iconName: 'mdiSwapHorizontal',
      statValue: jobStats.offer,
      iconColor: 'light-blue'
    },
    {
      statName: t('jobPositions.job.statistics.totalMatches'),
      iconName: 'mdiAccountGroup',
      statValue: jobStats.match,
      iconColor: 'rose'
    },
    {
      statName: t('jobPositions.job.statistics.archived'),
      iconName: 'mdiArchive',
      statValue: jobStats.archived,
      iconColor: 'purple'
    },
    {
      statName: t('jobPositions.job.statistics.interviews'),
      iconName: 'mdiMessageText',
      statValue: jobStats.interview,
      iconColor: 'red'
    },
    {
      statName: t('jobPositions.job.statistics.hired'),
      iconName: 'mdiThumbUp',
      statValue: jobStats.hired,
      iconColor: 'green'
    }
  ];
}

export function resolvePositionType(source: JobSource): string {
  if (source === Source.TALENT) {
    return 'Pro';
  }

  if (source === Source.JOB_BOARD) {
    return 'Core';
  }

  return '-';
}

export function resolveLocationCountry(
  companyCountry: JobPositionsLanguages,
  location: string[]
): string {
  if (companyCountry === Languages.ITALIAN) {
    return 'Italy';
  }

  if (companyCountry === Languages.SPANISH) {
    return 'Spain';
  }

  if (companyCountry === Languages.ENGLISH) {
    return 'England';
  }

  if (companyCountry === Languages.GERMAN) {
    return 'Germany';
  }

  return location.join(', ') || '-';
}

export function resolveJobPositionName(matchId: string): string {
  const matchesStore = useMatches();

  const match = matchesStore.matches?.find((el) => el.id === matchId);

  return match?.jobPosition?.name || '-';
}
