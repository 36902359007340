import type { ResponseBody } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Jobs/Stats/GET';
import type { ResponseBody as ResponseBodyJobsPositionsCompany } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Jobs/Company/GET';
import type { ResponseBody as ResponseBodyJobsPositionsWithCounts } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Jobs/GET';
import type { ResponseBody as ResponseBodyJobsPositionCreate } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Jobs/POST';
import type { ResponseBody as ResponseBodyJobsPosition } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Jobs/Id/GET';
import type { ResponseBody as ResponseBodyPutJobsPosition } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Jobs/Id/PUT';
import type { ResponseBody as ResponseBodyDelJobsPosition } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Jobs/Id/DELETE';
import type { JobPosition } from '@salvatore0193/definitions/lib/Jobs';
import { GET_JOBS_BY_COMPANY_REST, GET_JOBS_REST } from '~/constants';
import { execute } from '~/services/utils';

/**
 * @name getCandidateJobsStats
 * @description Stats of a Candidate for Job Positions
 */
const getCandidateJobsStats = async (
  uid: string
): Promise<ResponseBody['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBody['data']>(
    apiClient.get(GET_JOBS_REST + `/${uid}` + `/stats`)
  );
};

const getJobPositionsByCompanyId = async (
  uid: string
): Promise<ResponseBodyJobsPositionsCompany['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyJobsPositionsCompany['data']>(
    apiClient.get(GET_JOBS_BY_COMPANY_REST + `/${uid}`)
  );
};

const getJobPositionsById = async (
  uid: string
): Promise<ResponseBodyJobsPositionsWithCounts['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyJobsPositionsWithCounts['data']>(
    apiClient.get(GET_JOBS_REST + `/${uid}`)
  );
};

const getJobPositionsWithCounts = async (
  perPage: string,
  page: string,
  search?: string
): Promise<ResponseBodyJobsPositionsWithCounts['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyJobsPositionsWithCounts['data']>(
    apiClient.get(
      GET_JOBS_REST +
        (perPage && `?per_page=${perPage}`) +
        (page && `&page=${page}`) +
        (search ? `&search=${search}` : '')
    )
  );
};

const getJobPositionById = async (
  uid: string
): Promise<ResponseBodyJobsPosition['data'] | undefined> => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyJobsPosition['data']>(
    apiClient.get(GET_JOBS_REST + `/${uid}`)
  );
};

async function updateJobPosition(uid: string, job: JobPosition) {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyPutJobsPosition['data']>(
    apiClient.put(GET_JOBS_REST + `/${uid}`, job)
  );
}

async function deleteJobPosition(uid: string) {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyDelJobsPosition['data']>(
    apiClient.delete(GET_JOBS_REST + `/${uid}`)
  );
}

async function createJobPosition(job: JobPosition) {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyJobsPositionCreate['data']>(
    apiClient.post(GET_JOBS_REST, job)
  );
}

export default {
  getCandidateJobsStats,
  getJobPositionsByCompanyId,
  getJobPositionsById,
  getJobPositionsWithCounts,
  getJobPositionById,
  updateJobPosition,
  deleteJobPosition,
  createJobPosition
};
