import { ComputedRef } from 'vue';
import type { StatCardData } from '@emotioncod/cm-design-system/dist/types/types';
import type { CompanyManager } from '@salvatore0193/definitions/lib/Companies';
import {
  resolveCompaniesStats,
  resolveCompaniesTableData
} from '~/resolvers/companies';
import { filterArrayByPage } from '~/utils';
import type {
  CompanyUserListArr,
  SortableCompaniesArr,
  ClientPagination
} from '~/types/bo-types';

export const useCompaniesGetters = defineStore('companies.getters', () => {
  const companiesStore = useCompanies();

  const getCompaniesStats = (
    t: (key: string, ...args: any[]) => string
  ): StatCardData[] => {
    if (!companiesStore.counts) return [];

    return resolveCompaniesStats(companiesStore.counts, t);
  };

  function getCompaniesResolvedTableData(
    searhValue?: string
  ): SortableCompaniesArr[] {
    if (!companiesStore.companies) return [];

    const resolveCandidateDataTable = resolveCompaniesTableData(
      companiesStore.companies
    )
      .filter((item) => !item?.deleted_at)
      .filter((item) => {
        if (!searhValue) return true;

        return item.name.toLowerCase().includes(searhValue.toLowerCase());
      });

    if (searhValue) {
      return resolveCandidateDataTable;
    }

    /**
     * @description CmPagination has a index array from 0, so we call "page + 1"
     */
    function getCurrentPage() {
      if (companiesStore.pagination.currentPage === 0) return 1;

      if (companiesStore.pagination.currentPage) {
        return companiesStore.pagination.currentPage + 1;
      }

      return 1;
    }

    const currentPage = getCurrentPage();

    return filterArrayByPage(
      resolveCandidateDataTable,
      currentPage,
      companiesStore.pagination.perPage
    );
  }

  const getPagination = (): ClientPagination | undefined => {
    if (!companiesStore.pagination) return undefined;

    return {
      ...companiesStore.pagination,
      currentPage: companiesStore.pagination.currentPage,
      total: companiesStore.pagination.total,
      changePage: async (page: number) => {
        await companiesStore.changePage(page);
      }
    };
  };

  const getCompanyUsers = (search?: string) => {
    return (
      companiesStore.companyUsers
        .map((user: CompanyManager) => ({
          ...user,
          fullName: `${user.name} ${user.surname}`
        }))
        .filter((item: { deleted_at: string }) => !item?.deleted_at)
        // @ts-ignore
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .filter((user: CompanyUserListArr) => {
          if (!search) return true;

          return user.fullName.toLowerCase().includes(search.toLowerCase());
        })
    );
  };

  return {
    getCompaniesStats,
    getCompaniesResolvedTableData,
    getPagination,
    getCompanyUsers
  };
});
