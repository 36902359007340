import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import type { ModalArguments } from '~/types/bo-types';
import { getUser } from '~/services/my';

export enum ModalType {
  JOB_AFFINITY_MANDATORY = 'Job affinity mandatory',
  DELETE_CANDIDATE = 'Confirm delete',
  DELETE_USER = 'Confirm delete user',
  DELETE_SKILL = 'Confirm delete skill',
  PUBLISH_SHORTLIST = 'Confirm publish shortlist',
  RESTORE_TO_SHORTLIST = 'Confirm restore to shortlist',
  DELIST_MATCH_FROM_SHORTLIST = 'Confirm delist match from shortlist',
  DELIST_MATCH_FROM_SHORTLIST_AND_UPDATE_SHORTLIST = 'Confirm delist match from shortlist and after update shortlist',
  FAILED_FOR_POSITION_UNPUBLISHED = 'Confirm failed for position unpublished',
  FAILED_FOR_JOB_UNPUBLISHED = 'Confirm failed for job unpublished',
  PROPOSAL_ACCEPTED = 'Confirm proposal accepted',
  PROPOSAL_REJECTED = 'Confirm proposal rejected',
  OFFER_ACCEPTED = 'Confirm offer accepted',
  OFFER_REJECTED = 'Confirm offer rejected',
  REJECTED_BY_DEV = 'Confirm rejected by dev',
  REJECTED_BY_COMPANY = 'Confirm rejected by company',
  PUBLISH_ON_OPEN_SELECTIONS = 'Confirm publish on open selections',
  MOVE_SELECTION_TO_OFFER = 'Confirm move selection to offer',
  MOVE_SELECTION_TO_INTERVIEW = 'Confirm move selection to interview',
  ARCHIVE_JOB = 'Confirm archive job',
  LEAVING_THE_PAGE = 'Confirm leaving the page'
}

type Settings = {
  openMenu: boolean;
  snacknar: {
    message: string;
    color?: string;
    timeout: number;
    show: boolean;
  };
  showSidePanel: boolean;
  showModal: {
    title: string;
    message: string;
    confirmLabel: string;
    cancelLabel: string;
    show: boolean;
    confirmAction: () => void;
    cancelAction: () => void;
    args?: ModalArguments;
  };
  loggedIn: boolean;
  loading: boolean;
  user: {
    uid: string;
    email: string;
    profile: any;
  };
};

const InitialSettings: Settings = {
  openMenu: false,
  snacknar: {
    message: '',
    color: '',
    timeout: 0,
    show: false
  },
  showSidePanel: false,
  showModal: {
    title: '',
    message: '',
    confirmLabel: '',
    cancelLabel: '',
    show: false,
    confirmAction: () => {},
    cancelAction: () => {},
    args: {}
  },
  loggedIn: true, // TODO: change to false and connect to authState,
  loading: false,
  user: {
    uid: '',
    email: '',
    profile: null
  }
};

export const useSettings = defineStore('settingsStore', () => {
  const sentrySetUser = useNuxtApp().$sentrySetUser;

  const settings = ref<Settings>(InitialSettings);

  const { t } = useI18n();

  const showSnackabr = (message: string, color?: string, timeout = 3000) => {
    settings.value.snacknar = {
      message,
      color,
      timeout,
      show: true
    };
  };

  const showConfirmationDialog = (
    title: string,
    message: string,
    confirmLabel: string,
    cancelLabel: string,
    confirmAction: () => void,
    cancelAction: () => void
  ): void => {
    settings.value.showModal = {
      title,
      message,
      confirmLabel,
      cancelLabel,
      show: true,
      confirmAction,
      cancelAction
    };
  };

  const resetModal = () => {
    settings.value.showModal = {
      title: '',
      message: '',
      confirmLabel: '',
      cancelLabel: '',
      show: false,
      confirmAction: () => {},
      cancelAction: () => {}
    };
  };

  const showSpinner = (isActive: boolean) => {
    settings.value.loading = isActive;
  };

  /**
   * TODO:
   * Move this in other file
   */

  const modalData = computed(() => {
    return {
      [ModalType.JOB_AFFINITY_MANDATORY]: {
        title: t('dialogs.jobAffinityMandatory.title'),
        message: t('dialogs.jobAffinityMandatory.message'),
        confirmLabel: t('dialogs.jobAffinityMandatory.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.DELETE_CANDIDATE]: {
        title: t('dialogs.deleteCandidate.title'),
        message: t('dialogs.deleteCandidate.message'),
        confirmLabel: t('actions.delete'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.DELETE_USER]: {
        title: t('dialogs.deleteUser.title'),
        message: t('dialogs.deleteUser.message'),
        confirmLabel: t('actions.delete'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.DELETE_SKILL]: {
        title: t('dialogs.deleteSkill.title'),
        message: t('dialogs.deleteSkill.message'),
        confirmLabel: t('dialogs.deleteSkill.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.PUBLISH_SHORTLIST]: {
        title: t('dialogs.publishShortlist.title'),
        message: t('dialogs.publishShortlist.message'),
        confirmLabel: t('dialogs.publishShortlist.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.RESTORE_TO_SHORTLIST]: {
        title: t('dialogs.restoreToShortlist.title'),
        message: t('dialogs.restoreToShortlist.message', {
          jobName: settings.value.showModal.args?.jobName
        }),
        confirmLabel: t('dialogs.restoreToShortlist.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.DELIST_MATCH_FROM_SHORTLIST]: {
        title: t('dialogs.delistMatchFromShortlist.title'),
        message: t('dialogs.delistMatchFromShortlist.message'),
        confirmLabel: t('dialogs.delistMatchFromShortlist.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.DELIST_MATCH_FROM_SHORTLIST_AND_UPDATE_SHORTLIST]: {
        title: t('dialogs.delistMatchFromShortlistAndUpdateShortlist.title'),
        message: t(
          'dialogs.delistMatchFromShortlistAndUpdateShortlist.message'
        ),
        confirmLabel: t(
          'dialogs.delistMatchFromShortlistAndUpdateShortlist.confirmLabel'
        ),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.FAILED_FOR_POSITION_UNPUBLISHED]: {
        title: t('dialogs.failedForPositionUnpublished.title'),
        message: t('dialogs.failedForPositionUnpublished.message'),
        confirmLabel: t('dialogs.failedForPositionUnpublished.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.FAILED_FOR_JOB_UNPUBLISHED]: {
        title: t('dialogs.failedForJobUnpublished.title'),
        message: t('dialogs.failedForJobUnpublished.message'),
        confirmLabel: t('dialogs.failedForJobUnpublished.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.PROPOSAL_ACCEPTED]: {
        title: t('dialogs.proposalAccepted.title'),
        message: t('dialogs.proposalAccepted.message'),
        confirmLabel: t('dialogs.proposalAccepted.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.PROPOSAL_REJECTED]: {
        title: t('dialogs.proposalRejected.title'),
        message: t('dialogs.proposalRejected.message'),
        confirmLabel: t('dialogs.proposalRejected.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.OFFER_ACCEPTED]: {
        title: t('dialogs.offerAccepted.title'),
        message: t('dialogs.offerAccepted.message'),
        confirmLabel: t('dialogs.offerAccepted.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.OFFER_REJECTED]: {
        title: t('dialogs.offerRejected.title'),
        message: t('dialogs.offerRejected.message'),
        confirmLabel: t('dialogs.offerRejected.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.REJECTED_BY_DEV]: {
        title: t('dialogs.rejectedByDev.title'),
        message: t('dialogs.rejectedByDev.message'),
        confirmLabel: t('dialogs.rejectedByDev.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.REJECTED_BY_COMPANY]: {
        title: t('dialogs.rejectedByCompany.title'),
        message: t('dialogs.rejectedByCompany.message'),
        confirmLabel: t('dialogs.rejectedByCompany.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.PUBLISH_ON_OPEN_SELECTIONS]: {
        title: t('dialogs.publishOnOpenSelections.title'),
        message: t('dialogs.publishOnOpenSelections.message', {
          candidateFullname: settings.value.showModal.args?.candidateFullname,
          jobName: settings.value.showModal.args?.jobName,
          companyName: settings.value.showModal.args?.companyName,
          affinity: settings.value.showModal.args?.affinity
        }),
        confirmLabel: t('dialogs.publishOnOpenSelections.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.MOVE_SELECTION_TO_OFFER]: {
        title: t('dialogs.moveSelectionToOffer.title'),
        message: t('dialogs.moveSelectionToOffer.message', {
          jobName: settings.value.showModal.args?.jobName
        }),
        confirmLabel: t('dialogs.moveSelectionToOffer.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.MOVE_SELECTION_TO_INTERVIEW]: {
        title: t('dialogs.moveSelectionToInterview.title'),
        message: t('dialogs.moveSelectionToInterview.message', {
          jobName: settings.value.showModal.args?.jobName
        }),
        confirmLabel: t('dialogs.moveSelectionToInterview.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.ARCHIVE_JOB]: {
        title: t('dialogs.archiveJob.title'),
        message: t('dialogs.archiveJob.message'),
        confirmLabel: t('dialogs.archiveJob.confirmLabel'),
        cancelLabel: t('actions.cancel')
      },
      [ModalType.LEAVING_THE_PAGE]: {
        title: t('dialogs.leavingThePage.title'),
        message: t('dialogs.leavingThePage.message'),
        confirmLabel: t('dialogs.leavingThePage.confirmLabel'),
        cancelLabel: t('actions.cancel')
      }
    };
  });

  const showModal = (
    modalType: ModalType,
    args?: ModalArguments
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      settings.value.showModal.args = args;

      if (modalData.value) {
        showConfirmationDialog(
          modalData.value[modalType].title,
          modalData.value[modalType].message,
          modalData.value[modalType].confirmLabel,
          modalData.value[modalType].cancelLabel,
          () => {
            settings.value.showModal.show = false;
            resolve(true);
          },
          () => {
            settings.value.showModal.show = false;
            resolve(false);
          }
        );
      } else {
        resolve(false); // Gestisci il caso in cui il tipo di modale non sia valido
      }
    });
  };

  const setUser = async () => {
    if (settings.value.user.uid) {
      sentrySetUser({
        email: settings.value.user.email,
        id: settings.value.user.uid
      });
    } else {
      const user = await getUser();

      settings.value.user = {
        uid: user?.uid || '',
        email: user?.email || '',
        profile: user?.profile || null
      };

      sentrySetUser({
        email: settings.value.user.email,
        id: settings.value.user.uid
      });
    }
  };

  return {
    settings,
    showSnackabr,
    showModal,
    showConfirmationDialog,
    resetModal,
    showSpinner,
    setUser
  };
});
