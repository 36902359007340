import type {
  SelectItem,
  StatCardData
} from '@emotioncod/cm-design-system/dist/types/types';
import type {
  CompanyWithCounts,
  CompanyList
} from '@salvatore0193/definitions/lib/Companies';
import { useCompanies } from '~/stores/companies';
import type { SortableCompaniesArr } from '~/types/bo-types';

export function resolveCompanyNameFromId(id: string): string {
  const companyFind = useCompanies().companies.find(
    (company: CompanyList) => company.id === id
  );

  if (companyFind) {
    return companyFind.name;
  }

  return '';
}

export function resolveCompanyListFromId(
  id: string | undefined
): CompanyList | undefined {
  if (!id) return undefined;

  return useCompanies().companies.find(
    (company: CompanyList) => company.id === id
  );
}

export function resolveCompanyFromSlug(slug: string): CompanyList | undefined {
  return useCompanies().companies.find(
    (company: CompanyList) => company.slug === slug
  );
}

export function resolveCompaniesStats(
  counts: CompanyWithCounts['counts'],
  t: (key: string, ...args: any[]) => string
): StatCardData[] {
  if (!counts) return [];

  return [
    {
      statName: t('companies.stats.activeCompanies'),
      iconName: 'mdiAccountGroup',
      statValue: counts.active,
      iconColor: 'purple'
    },
    {
      statName: t('companies.stats.italyActive'),
      iconName: 'mdiTargetAccount',
      statValue: `Pro: ${counts.italy.pro} - Core: ${counts.italy.core}`,
      iconColor: 'blue'
    },
    {
      statName: t('companies.stats.spainActive'),
      iconName: 'mdiThumbUp',
      statValue: `Pro: ${counts.spain.pro} - Core: ${counts.spain.core}`,
      iconColor: 'red'
    },
    {
      statName: t('companies.stats.activeTalentCore'),
      iconName: 'mdiCommentAccount',
      statValue: counts.activeTalent,
      iconColor: 'green'
    },
    {
      statName: t('companies.stats.activeTalentPro'),
      iconName: 'mdiArchive',
      statValue: counts.activeTalentPro,
      iconColor: 'light-blue'
    }
  ];
}

export function resolveCompaniesTableData(
  companies: Array<CompanyList>
): SortableCompaniesArr[] {
  return companies.map((el) => ({
    ...el,
    latestContractDate: '2022-05-09 12:00:00',
    country: 'Italy'
  }));
}

export function resolveCompaniesSelectItems(
  companies: Array<CompanyList>
): SelectItem[] {
  return companies.map((el) => ({
    value: el.id,
    text: el.name
  }));
}
