import type { ResponseBody } from '@salvatore0193/definitions/lib/ServiceLayer/Companies/POST';
import { execute } from '~/services/utils';
import { POST_COMPANIES_BY_IDS } from '~/constants';

const getCompaniesByIdArray = async (uid: Array<string>) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBody['data']>(
    apiClient.post(POST_COMPANIES_BY_IDS, { ids: uid })
  );
};

export default {
  getCompaniesByIdArray
};
