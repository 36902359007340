import type { ResponseBody as ResponseBodyStatus } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Match/PUT/Status';
import type { ResponseBody as ResponseBodyShortlist } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Matches/Shortlist/POST';
import type { ResponseBody as ResponseBodyEvent } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Match/POST/Event';
import type { ResponseBody as ResponseBodyMatch } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Match/POST';
import type { ResponseBody as ResponseBodyUpdateMatch } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Match/PUT';
import type {
  EventMatch,
  Match,
  MatchStatus
} from '@salvatore0193/definitions/lib/Matches';
import {
  MATCHES_BY_CANDIDATE_TO_SHORTLIST_REST,
  PUT_MATCH_REST
} from '~/constants';
import { execute } from '~/services/utils';

const updateMatchStatusById = async (matchId: string, status: MatchStatus) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyStatus['data']>(
    apiClient.put(PUT_MATCH_REST + `/${matchId}/status`, {
      status
    })
  );
};

const postEventMatch = async (matchId: string, event: Partial<EventMatch>) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyEvent['data']>(
    apiClient.post(PUT_MATCH_REST + `/${matchId}/event`, {
      event
    })
  );
};

/**
 * @name updateShortListStatusById
 * @description Update the shortlist status after Publish Shortlist on my talent
 * @param userId
 * @param matches
 */
const publishShortListArray = async (userId: string, matches: Match[]) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyShortlist['data']>(
    apiClient.post(MATCHES_BY_CANDIDATE_TO_SHORTLIST_REST + `/${userId}`, {
      matches
    })
  );
};

const addNewMatch = async (match: Partial<Match>) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyMatch['data']>(
    apiClient.post(PUT_MATCH_REST, match)
  );
};

const updateMatch = async (matchId: string, match: Partial<Match>) => {
  const apiClient = useNuxtApp().$axiosInstance;

  return await execute<ResponseBodyUpdateMatch['data']>(
    apiClient.put(PUT_MATCH_REST + `/${matchId}`, match)
  );
};

export default {
  updateMatchStatusById,
  publishShortListArray,
  postEventMatch,
  addNewMatch,
  updateMatch
};
