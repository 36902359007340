import type { JobPosition } from '@salvatore0193/definitions/lib/Jobs';

interface State {
  jobPositions: JobPosition[];
  counts: {
    jobs: number;
    online: number;
    offline: number;
    candidatesSent: number;
    openSelections: number;
  };
  job: JobPosition | null;
  pagination: {
    currentPage: number;
    total: number;
    perPage: number;
  };
  cachedResult: number;
}

const InitialState: State = {
  jobPositions: [],
  counts: {
    jobs: 0,
    online: 0,
    offline: 0,
    candidatesSent: 0,
    openSelections: 0
  },
  job: null,
  pagination: {
    currentPage: 0,
    total: 1,
    perPage: 10
  },
  cachedResult: 0
};
export const useJobsPositionsState = defineStore('jobPositions.state', () => {
  const jobPositions = ref<State['jobPositions']>(InitialState.jobPositions);
  const pagination = ref<State['pagination']>(InitialState.pagination);
  const cachedResult = ref<State['cachedResult']>(InitialState.cachedResult);
  const job = ref<State['job']>(InitialState.job);
  const counts = ref<State['counts']>(InitialState.counts);

  return {
    jobPositions,
    pagination,
    cachedResult,
    job,
    counts
  };
});
