import { ComputedRef } from 'vue/dist/vue';
import {
  EventType,
  Status
} from '@salvatore0193/definitions/lib/Matches/const';
import type { Match } from '@salvatore0193/definitions/lib/Matches';
import { resolveCompanyNameFromId } from '~/resolvers/companies';
import { formatDate } from '~/utils';
import type { ClientPagination } from '~/types/bo-types';
import { resolvePositionType } from '~/resolvers/jobPositions';

function remappingMatchesForTableFields(
  resultFiltered: Match[]
): (Match & { companyName: string; positionType: string })[] {
  return resultFiltered.map((match) => {
    return {
      ...match,
      created_at: match?.created_at
        ? formatDate(match.created_at)
        : formatDate(match?.date),
      companyName: resolveCompanyNameFromId(
        match.jobPosition?.company?.id || match.jobPosition?.company_id || ''
      ),
      positionType: match.jobPosition?.source
        ? resolvePositionType(match.jobPosition?.source)
        : '-'
    };
  });
}

export const useMatchesGetters = defineStore('matches.getters', () => {
  const matchesStore = useMatches();

  function getMatchInShortlist(): (Match & {
    companyName: string;
  })[] {
    const resultFiltered = matchesStore.matches
      .filter(
        (item: Match) =>
          item.status.toLowerCase() === Status.MATCH.toLowerCase() &&
          item.shortlistStatus?.name.toLowerCase() !==
            EventType.DELISTED.toLowerCase()
      )
      .filter((item: Match) => !item?.deleted_at);

    return remappingMatchesForTableFields(resultFiltered);
  }

  function getMatchInOpportunityStatus() {
    const resultFiltered: Match[] = matchesStore.matches
      ?.filter(
        (item: Match) =>
          item.status.toLowerCase() === Status.OPPORTUNITY.toLowerCase()
      )
      .filter((item: Match) => !item?.deleted_at);

    return remappingMatchesForTableFields(resultFiltered);
  }

  function getMatchOpenSelectionStatus() {
    const validStatusOpenSelections = [
      Status.SELECTION.toLowerCase(),
      Status.INTERVIEW.toLowerCase(),
      Status.OFFER.toLowerCase()
    ];

    const resultFiltered: Match[] = matchesStore.matches
      ?.filter(
        (item: Match) =>
          validStatusOpenSelections.includes(item.status.toLowerCase()) &&
          item.shortlistStatus?.name.toLowerCase() ===
            EventType.PUBLISHED_IN_SHORTLIST.toLowerCase()
      )
      .filter((item: Match) => !item?.deleted_at);

    return remappingMatchesForTableFields(resultFiltered);
  }

  function getMatchHistoryStatus() {
    const validStatusHistory = [
      Status.FAILED_MATCH.toLowerCase(),
      Status.FAILED_SELECTION.toLowerCase(),
      Status.ARCHIEVED.toLowerCase(),
      Status.HIRED.toLowerCase()
    ];

    const resultFiltered: Match[] = matchesStore.matches
      ?.filter(
        (item: Match) =>
          validStatusHistory.includes(item.status.toLowerCase()) ||
          item.shortlistStatus?.name.toLowerCase() ===
            EventType.DELISTED.toLowerCase()
      )
      .filter((item: Match) => !item?.deleted_at);

    return remappingMatchesForTableFields(resultFiltered);
  }

  function getCompanyMatches(search?: string) {
    const resultFiltered: Match[] = matchesStore.matches
      .filter(
        (item: Match) =>
          item.status.toLowerCase() === Status.MATCH.toLowerCase() &&
          item.shortlistStatus?.name.toLowerCase() !==
            EventType.DELISTED.toLowerCase()
      )
      .filter((item: Match) => !item?.deleted_at)
      .filter((match: Match) => {
        if (!search) return true;

        return match.candidate?.surname
          .toLowerCase()
          .includes(search.toLowerCase());
      });

    return remappingMatchesForTableFields(resultFiltered);
  }

  function getCompanyApplications(search?: string): Match[] {
    return matchesStore.matches
      .filter(
        (item: Match) =>
          item.status.toLowerCase() === Status.OPPORTUNITY.toLowerCase()
      )
      .filter((match: Match) => {
        if (!search) return true;

        return match.candidate?.surname
          .toLowerCase()
          .includes(search.toLowerCase());
      })
      .filter((item: Match) => !item?.deleted_at);
  }

  function getCompanyOpenSelections(search?: string): Match[] {
    const validStatusOpenSelections = [
      Status.SELECTION.toLowerCase(),
      Status.INTERVIEW.toLowerCase(),
      Status.OFFER.toLowerCase()
    ];

    return matchesStore.matches
      .filter(
        (item: Match) =>
          validStatusOpenSelections.includes(item.status.toLowerCase()) &&
          item.shortlistStatus?.name.toLowerCase() ===
            EventType.PUBLISHED_IN_SHORTLIST.toLowerCase()
      )
      .filter((match: Match) => {
        if (!search) return true;

        return match.candidate?.surname
          .toLowerCase()
          .includes(search.toLowerCase());
      })
      .filter((item: Match) => !item?.deleted_at);
  }

  function getCompanyHistory(search?: string): Match[] {
    const validStatusHistory = [
      Status.FAILED_MATCH.toLowerCase(),
      Status.FAILED_SELECTION.toLowerCase(),
      Status.ARCHIEVED.toLowerCase(),
      Status.HIRED.toLowerCase()
    ];

    return matchesStore.matches
      .filter(
        (item: Match) =>
          validStatusHistory.includes(item.status.toLowerCase()) ||
          item.shortlistStatus?.name.toLowerCase() ===
            EventType.DELISTED.toLowerCase()
      )
      .filter((match: Match) => {
        if (!search) return true;

        return match.candidate?.surname
          .toLowerCase()
          .includes(search.toLowerCase());
      })
      .filter((item: Match) => !item?.deleted_at);
  }

  function getMatchesResolved() {
    const result = remappingMatchesForTableFields(matchesStore.matches);

    return result
      .filter((item) => !item?.deleted_at) // @ts-ignore
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }

  const getPagination: ComputedRef<ClientPagination> | undefined = computed(
    () => {
      if (!matchesStore.pagination) return undefined;

      return {
        ...matchesStore.pagination,
        currentPage: matchesStore.pagination.currentPage,
        total: matchesStore.pagination.total,
        changePage: async (page: number) => {
          await matchesStore.changePage(page);
        }
      };
    }
  );

  return {
    getMatchInShortlist,
    getMatchInOpportunityStatus,
    getMatchOpenSelectionStatus,
    getMatchHistoryStatus,
    getCompanyMatches,
    getCompanyApplications,
    getCompanyOpenSelections,
    getCompanyHistory,
    getMatchesResolved,
    getPagination
  };
});
