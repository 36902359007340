import type { AxiosError } from 'axios';
import { CMError } from '~/errors/index';

const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).name === 'AxiosError';
};

/**
 * Handle HTTP errors
 * @name HTTPHandleError
 * @description At moment, this function only handle AxiosError
 * @param error
 * @constructor
 */
export function HTTPHandleError(
  error: unknown,
  snackback?: (message: string) => void
): void {
  if (isAxiosError(error)) {
    if (error.response) {
      if (error.response.status === 401) {
        snackback?.('Unauthorized');

        throw new CMError(
          `Unauthorized - ${error.message}`,
          CMError.ERROR_FAMILY.LoggedIn,
          CMError.ERROR_TYPE.Fatal,
          error
        );
      }
      if (error.response.status === 409) {
        snackback?.('Conflict - There is another event with the same data');

        throw new CMError(
          `Conflict - There is another event with the same data`,
          CMError.ERROR_FAMILY.InvalidResponse,
          CMError.ERROR_TYPE.Fatal,
          error
        );
      }
    } else if (error.request) {
      snackback?.('Error on request - TServer does not respond');

      throw new CMError(
        `Error on request - TServer does not respond`,
        CMError.ERROR_FAMILY.InvalidResponse,
        CMError.ERROR_TYPE.Fatal,
        error
      );
    }
  } else if (error instanceof Error) {
    snackback?.('Unknown - Error while fetching data');

    throw new CMError(
      `Unknown - Error while fetching data`,
      CMError.ERROR_FAMILY.Unknown,
      CMError.ERROR_TYPE.Fatal,
      error
    );
  }
  snackback?.(`${error}`);

  throw new CMError(
    `Error Unknown | ${error}`,
    CMError.ERROR_FAMILY.Unknown,
    CMError.ERROR_TYPE.Fatal
  );
}
