import {
  parseISO,
  isValid,
  format,
  Locale,
  isSameDay,
  differenceInMinutes,
  parse
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
// eslint-disable-next-line import/no-duplicates
import { it } from 'date-fns/locale';
import { EventType } from '@salvatore0193/definitions/lib/Matches/const';
import type { EventMatch } from '@salvatore0193/definitions/lib/Matches';
import type { CandidateLogEvents, LogEvents } from '~/types/bo-types';

const romaTimezone = 'Europe/Rome';

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

/**
 * Filter an array by page
 * Use this function if server-side pagination is not available
 * @param array
 * @param total
 * @param currentPage
 * @param perPage
 */
export function filterArrayByPage<T>(
  array: T[],
  currentPage: number,
  perPage: number
): T[] {
  if (perPage === 0) return array;

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  // Check if the start index is within array bounds
  if (startIndex >= array.length) {
    return [];
  }

  // If the end index exceeds the total number, adjust it
  const filteredEndIndex = Math.min(endIndex, array.length);

  return array.slice(startIndex, filteredEndIndex);
}

/**
 * Deeply map an array of objects
 * This function is very waighed, use it only when needed
 * @param array
 */
export function deepMap<T>(array: T[]): T[] {
  return array.map((item) => {
    const mappedItem = { ...item };

    for (const key in mappedItem) {
      if (Object.prototype.hasOwnProperty.call(mappedItem, key)) {
        if (typeof mappedItem[key] === 'object') {
          mappedItem[key] = deepMap([mappedItem[key]])[0];
        }
      }
    }

    return mappedItem;
  });
}

/**
 * Debounce function
 * TODO: Type this function
 * @param func
 * @param wait
 */
export const debounce = (func: (a: any) => void, wait: number | undefined) => {
  let timeout: ReturnType<typeof setTimeout> | null;

  return function executedFunction(...args: any[]) {
    const later = () => {
      timeout = null;

      // @ts-ignore
      func(...args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);
  };
};

export function formatDate(
  date: string,
  isTimeStamp?: boolean,
  isInputForm = false
): string {
  const utcDate = new Date();
  const now = utcToZonedTime(utcDate, romaTimezone);
  let formattedDate = '';

  let parsedDate: Date;
  if (isTimeStamp) {
    const timestamp = Number(date);
    parsedDate = isNaN(timestamp)
      ? parseISO(date)
      : utcToZonedTime(new Date(timestamp), romaTimezone);
  } else {
    parsedDate = parseISO(date);
  }

  const isValidDate = isValid(parsedDate);

  if (isValidDate) {
    const isToday = isSameDay(parsedDate, now);

    if (isToday && !isInputForm) {
      const diffInMinutes = differenceInMinutes(now, parsedDate);
      const hours = Math.floor(diffInMinutes / 60);
      const minutes = diffInMinutes % 60;

      if (hours > 0) {
        formattedDate = `${hours} hours ${minutes} minutes ago`;
      } else if (minutes === 0) {
        formattedDate = `now`;
      } else {
        formattedDate = `${minutes} minutes ago`;
      }
    } else {
      formattedDate = format(parsedDate, 'P', {
        locale: it as Locale
      });
    }
  }

  return formattedDate;
}

export function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

/**
 * @todo unify the CandidateEvents interface and the EventMatch interface
 */
export function sortEventsDates(
  events: CandidateLogEvents,
  orderBy: 'asc' | 'desc',
  filerBy: 'all' | 'candidateNotes' | 'matchNotes' | 'matchEvents'
): LogEvents[] {
  if (!events) return [];
  let result = [];

  const compareDates = (
    event1: { date: string | undefined },
    event2: { date: string | undefined }
  ): number => {
    if (!event1.date || !event2.date) return 0;

    const parsedDate1 = new Date(event1.date).getTime();
    const parsedDate2 = new Date(event2.date).getTime();

    if (orderBy === 'asc') {
      return parsedDate1 - parsedDate2;
    } else {
      return parsedDate2 - parsedDate1;
    }
  };

  if (filerBy !== 'all') {
    result = events[filerBy].sort(compareDates);
  } else {
    result = [
      ...events?.matchEvents,
      ...events?.matchNotes,
      ...events?.candidateNotes
    ].sort(compareDates);
  }

  if (orderBy === 'desc') {
    result.reverse();
  }

  return result.map((event) => ({
    authorName: event.authorName || '',
    content: event.content || '',
    type: event.type || '',
    source: event.source || '',
    date: event.date || '',
    deleted_at: event.deleted_at || '',
    jobsName: event.jobsName || '',
    jobsId: event.jobsId || '',
    companyName: event.companyName || '',
    companyId: event.companyId || '',
    by: event.by || ''
  }));
}

export function groupEventsByDate(
  events: Array<LogEvents>,
  orderBy: 'asc' | 'desc'
): {
  [date: string]: Array<LogEvents>;
} {
  if (!events) return {};

  const sortedEvents = [...events].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    return dateA.getTime() - dateB.getTime();
  });

  if (orderBy === 'asc') {
    sortedEvents.reverse();
  }

  return sortedEvents.reduce(
    (
      acc: {
        [date: string]: Array<LogEvents>;
      },
      event: LogEvents
    ) => {
      const now = new Date();
      const parsedDate = parseISO(event.date);
      const isToday = isSameDay(parsedDate, now);
      let date;

      if (isToday) {
        date = 'Today';
      } else {
        const parsedDate = parseISO(event.date);

        // @ts-ignore
        if (isValid(parsedDate)) {
          date = format(parsedDate, 'MMM dd, yyyy');
        } else {
          date = 'Invalid Date';
        }
      }

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(event);
      return acc;
    },
    {}
  );
}

export function parseDataStringToISO(dataInput: string): string {
  const parsedDate = parse(dataInput, 'dd/MM/yyyy', new Date());

  return format(parsedDate, 'yyyy-MM-dd HH:mm:ss');
}

export const handleOpenActionsMenu = (
  event: CustomEvent<boolean> & { target: HTMLElement },
  setPadding: (value: number) => void,
  setMenuOpen: (value: boolean) => void
) => {
  if (!event.target) return;

  setTimeout(() => {
    const dropDown = event.target.getElementsByClassName(
      'cm-dropdown-container'
    );

    if (dropDown) {
      setPadding(dropDown[0]?.getBoundingClientRect().height);
    }

    setMenuOpen(event.detail);
  }, 300);
};

export function isNoteEmpty(events: Partial<EventMatch>[]): boolean {
  if (!events || events.length === 0) return true;

  const someNoteAdded = events.some((event) => {
    return (
      event.type?.toLowerCase() === EventType.NOTE_ADDED.toLowerCase() ||
      event.type?.toLowerCase() === EventType.NOTE.toLowerCase()
    );
  });

  return !someNoteAdded;
}

export const concatStringsErrors = (
  errors: { $message: string; $response: { $message?: string } }[]
) => {
  return errors.map((error) => error.$message).join('\n');
};

export function addVersionNumber(version: string, versionToAdd: string) {
  const versionNumbers = version.split('.').map(Number);
  const versionToAddNumbers = versionToAdd.split('.').map(Number);

  for (let i = 0; i < versionNumbers.length; i++) {
    versionNumbers[i] += versionToAddNumbers[i];
  }

  return versionNumbers.join('.');
}

export function toCapitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
