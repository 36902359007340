import type { ResponseBody } from '@salvatore0193/definitions/lib/ServiceLayer/Admin/Jobs/GET';
import type { JobPosition } from '@salvatore0193/definitions/lib/Jobs';
import JobsServices from '~/services/admin/jobs';
import { useJobsPositions } from '~/stores/jobPositions/index';
import { sessionTTL } from '~/constants';
import { el } from 'date-fns/locale';

export const useJobsPositionsActions = defineStore(
  'jobPositions.actions',
  () => {
    const { settings } = useSettings();
    const jobPositionsStore = useJobsPositions();

    const changePage = (page: number): void => {
      jobPositionsStore.pagination.currentPage = page;
    };

    const updateJobPositions = (m: ResponseBody['data']): void => {
      if (!m) return;

      jobPositionsStore.jobPositions = [...m.list.data];
      jobPositionsStore.counts = m.counts;
      jobPositionsStore.pagination.total = m.list.last_page - 1;
      jobPositionsStore.pagination.perPage = Number(m.list.per_page);
    };

    const updateCacheResult = (timestamp: number): void => {
      jobPositionsStore.cachedResult = timestamp;
    };

    async function getJobPositionsByCompanyId(uid: string) {
      if (!settings.loggedIn) {
        return undefined;
      }

      const response = await JobsServices.getJobPositionsByCompanyId(uid);

      if (response) {
        jobPositionsStore.jobPositions = response;
        return response;
      }
    }

    async function getJobPositions(
      withPage?: number,
      searchValue?: string,
      perPage = 10
    ) {
      if (!settings.loggedIn) {
        return undefined;
      }

      if (
        jobPositionsStore.cachedResult &&
        jobPositionsStore.cachedResult + sessionTTL < Date.now() &&
        !searchValue
      ) {
        return undefined;
      }

      /**
       * @description CmPagination has a index array from 0, so we call "page + 1"
       */
      function getCurrentPage(): number {
        if (withPage === 0) return 1;

        if (withPage) {
          return withPage + 1;
        }

        if (jobPositionsStore.pagination.currentPage) {
          return jobPositionsStore.pagination.currentPage + 1;
        }

        return 1;
      }

      const currentPage = getCurrentPage();

      const response = await JobsServices.getJobPositionsWithCounts(
        perPage.toString(),
        currentPage.toString(),
        searchValue
      );

      if (response) {
        updateJobPositions(response);
        updateCacheResult(Date.now());
        return response.list;
      }
    }

    async function getCandidateJobsStats(jobId: string) {
      if (!settings.loggedIn) {
        return undefined;
      }

      const response = await JobsServices.getCandidateJobsStats(jobId);

      if (response) {
        return response;
      }
    }

    async function getJobPositionById(jobId: string) {
      if (!settings.loggedIn) {
        return undefined;
      }

      const response = await JobsServices.getJobPositionById(jobId);

      if (response) {
        jobPositionsStore.job = response;
        return response;
      }
    }

    async function updateJobPosition(uid: string, job: JobPosition) {
      if (!settings.loggedIn) {
        return undefined;
      }

      const response = await JobsServices.updateJobPosition(uid, job);

      if (response) {
        jobPositionsStore.job = response;

        const indexJobArray = jobPositionsStore.jobPositions.findIndex(
          (el) => el.id === response.id
        );

        if (indexJobArray !== -1) {
          jobPositionsStore.jobPositions = [
            ...jobPositionsStore.jobPositions.slice(0, indexJobArray),
            response,
            ...jobPositionsStore.jobPositions.slice(indexJobArray + 1)
          ];
        }

        return response;
      }
    }

    async function deleteJobPosition(uid: string) {
      if (!settings.loggedIn) {
        return undefined;
      }

      const response = await JobsServices.deleteJobPosition(uid);

      if (response) {
        jobPositionsStore.job = null;
        return response;
      }
    }

    async function createJobPosition(job: JobPosition) {
      if (!settings.loggedIn) {
        return undefined;
      }

      const response = await JobsServices.createJobPosition(job);

      if (response) {
        jobPositionsStore.job = response;
        return response;
      }
    }

    return {
      getJobPositionsByCompanyId,
      getJobPositions,
      changePage,
      getCandidateJobsStats,
      getJobPositionById,
      updateJobPosition,
      deleteJobPosition,
      createJobPosition
    };
  }
);
